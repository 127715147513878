<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item class="mb10" label="用户名">
						<el-input v-model.trim="filters.username" placeholder="输入用户名" clearable></el-input>
					</el-form-item>
					<el-form-item label="店名">
						<el-input v-model.trim="filters.name" placeholder="输入店名" clearable></el-input>
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model.trim="filters.mobile" placeholder="输入手机号" clearable></el-input>
					</el-form-item>
					<el-form-item label="域名">
						<el-input v-model.trim="filters.my_host" placeholder="输入域名" clearable></el-input>
					</el-form-item>
					<el-form-item label="开通时间">
						<el-date-picker v-model="time" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
					</el-form-item>
					<el-form-item label="到期时间">
						<el-date-picker v-model="filters.server_end" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
					</el-form-item>
					<!-- <el-form-item label="版本">
						<el-select v-model="filters.server_level" placeholder="请选择" clearable>
							<el-option v-for="item in serverType" :key="item.label" :label="item.value" :value="item.label">
							</el-option>
						</el-select>
					</el-form-item> -->
					<!-- <el-form-item label="地区">
						<el-input v-model.trim="filters.area_desc" placeholder="选择地区" clearable></el-input>
					</el-form-item> -->
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">查询</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="id" width="60">
			</el-table-column>	
			<el-table-column prop="username" align="center" label="用户名" width="80">
			</el-table-column>
			<el-table-column prop="name" label="店铺名称" width="150">
			</el-table-column>
			<el-table-column prop="mobile" label="手机号" width="120">
			</el-table-column>
			<el-table-column prop="my_host" label="域名" width="180">
			</el-table-column>
			<!-- sortable -->
			<el-table-column prop="host_open_at" label="开通时间" align="center" width="120">
			</el-table-column>
			<el-table-column prop="server_end_str" label="到期时间" align="center" width="120">
			</el-table-column>
			<!-- <el-table-column prop="area_desc" label="地区">
			</el-table-column> -->
			<el-table-column prop="store_status" align="center" label="店铺状态" width="80">
			</el-table-column>
			<el-table-column prop="version_str" align="center" label="当前版本" width="80">
			</el-table-column>
			<el-table-column prop="balance" label="服务点" width="70">
			</el-table-column>
			<el-table-column label="站点信息" align="center" width="460">
				<template slot-scope="scope">
					<template>
						<el-button v-if="scope.row.version_str == '高级版'" type="primary" plain size="small" @click="setModule(scope.row.id,scope.row.store_id)">模块</el-button>
						<el-button v-if="scope.row.version_str == '标准版'" type="primary" plain size="small" @click="setModule(scope.row.id,scope.row.store_id)">升级高级版</el-button>
						<el-button type="primary" plain size="small" @click="seeInfo(scope.$index, scope.row)">修改</el-button>
						<!-- <el-button type="primary" plain size="small" @click="permiss(scope.row.id)">权限</el-button> -->
						<el-button type="primary" plain size="small" @click="setHz(scope.row.username, scope.row.name)">设置合作</el-button>
						<el-button type="primary" plain size="small" @click="setFwd(scope.row.store_id, scope.row.name)">服务点</el-button>
						<!-- <el-button type="primary" plain size="small" @click="handleLog(scope.$index, scope.row)">操作日志</el-button>
						<el-button type="primary" plain size="small" @click="seepayLog(scope.$index, scope.row)">付费记录</el-button> -->
						<el-button type="primary" plain size="small" @click="toNoticeSend(scope.row)">发送通知</el-button> 
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

		<el-dialog title="店铺合作设置" :visible.sync="showHz" width="800px" center>
			<el-form label-width="100px">
				<el-form-item label="派单店铺：">
					{{from.store_username}} -- {{store_name}}
				</el-form-item>
				<el-form-item label="出票店铺：">
					<el-input v-model="from.ticket_username" placeholder="出票店铺账号" class="w100"></el-input>
				</el-form-item>
				<el-form-item label="彩种和点位：">
					<el-checkbox-group v-model="chosed" class="cz">
						<el-checkbox v-for="(item,index) in lotterType" :key="index" :label="item.lottery_type">{{item.lottery_name}}， 点位<el-input v-model="item.rate" class="rate"></el-input></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">提 交</el-button>
				<el-button @click="closeHz">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog title="服务点设置" :visible.sync="showFwd" width="600px" center>
			<el-form label-width="100px">
				<el-form-item label="店铺信息：">
					{{fromFwd.store_id}} -- {{store_name}}
				</el-form-item>
				<el-form-item label="增加点数：">
					<el-input v-model="fromFwd.money" placeholder="输入添加的服务点" class="w100"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFwd">提 交</el-button>
				<el-button @click="closeFwd">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import { storeData,modulesUpdate,coopBind,addServicePoint } from '../../api/api';
	// import {serverType} from '@/utils/const';
	export default {
		data() {
			return {
				// serverType,
				time:[],
				showHz: false,
				showFwd: false,
				chosed: [2,4],
				lotterTypeStr: '',
				lotterType:[
					{ lottery_type: 1, lottery_name: "竞彩足球", rate: '6' },
					{ lottery_type: 2, lottery_name: "北京单场", rate: '6' },
					{ lottery_type: 3, lottery_name: "足彩", rate: '6' },
					{ lottery_type: 4, lottery_name: "胜负过关", rate: '6' },
					{ lottery_type: 5, lottery_name: "竞彩篮球", rate: '6' },
					{ lottery_type: 6, lottery_name: "超级大乐透", rate: '6' },
					{ lottery_type: 7, lottery_name: "排列三", rate: '6' },
					{ lottery_type: 8, lottery_name: "排列五", rate: '6' },
					{ lottery_type: 9, lottery_name: "七星彩", rate: '6' },
					{ lottery_type: 11, lottery_name: "福彩3D", rate: '6' },
					{ lottery_type: 12, lottery_name: "双色球", rate: '6' }
				],
				from: {
					store_username: '',
					ticket_username: '',
					lottery_rate: ''
				},
				fromFwd: {
					store_id: '',
					money: ''
				},
				store_name: '',
				filters: {
					username:'',
					name:'',
					mobile:'',
					my_host:'',
					start_time:  '',
					end_time: '',
					server_end: [],
					store_status:'',
					area_desc:'',
				},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				sels: [],//列表选中列
				options: [
					{
						value: '1',
						label: '标准版'
					},
					{
						value: '2',
						label: '高级版'
					}
				],
			}
		},
		methods: {
			setFwd(id, name){
				this.fromFwd.store_id = id
				this.store_name = name
				this.showFwd = true
			},
			closeFwd() {
				this.fromFwd.store_id = ''
				this.fromFwd.money = ''
				this.store_name = ''
				this.showFwd = false
			},
			submitFwd(){
				if(!this.fromFwd.money) return this.$message.error('服务点不能为空！')
				// 请求数据
				addServicePoint(this.fromFwd).then((res) => {
					if(res.code == 1){
						this.closeFwd()
						this.getList();
					}
				});
			},
			setHz(id, name){
				this.from.store_username = id
				this.store_name = name
				this.showHz = true
			},
			submit(){
				let arr = []
				this.chosed.forEach( item => {
					this.lotterType.some( item1 => {
						if(item == item1.lottery_type){
							arr.push(item1)
							return true
						}
					})
				});
				this.from.lottery_rate = JSON.stringify(arr)
				// 请求数据
				coopBind(this.from).then((res) => {
					if(res.code == 1){
						this.closeHz()
					}
				});
			},
			closeHz() {
				this.chosed = [2,4],
				this.lotterType = JSON.parse(this.lotterTypeStr)
				this.clearData(this.from)
				this.store_name = ''
				this.showHz = false
			},
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
                if(val == '0') this.page = 1;
				let para = {
					page: this.page,
					pagesize: this.pagesize	//分页条数
				};
				let newObj = {};
				Object.assign(newObj,para,this.filters)
				this.listLoading = true;
				storeData(newObj).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			setModule: function (id,store_id) {
				localStorage.setItem('dataListFrome', JSON.stringify(this.filters))
				localStorage.setItem('dataListPage', this.page)
				this.$router.push({ name: '模块定制', query: { id: id,store_id:store_id }})
			},
			seeInfo: function (index, row) {				
				localStorage.setItem('dataListFrome', JSON.stringify(this.filters))
				localStorage.setItem('dataListPage', this.page)
				this.$router.push({ name: '店铺详细信息', query: { id: row.id }})
			},
			handleLog: function (index, row) {
				this.$router.push({ name: '店铺操作日志', query: { id: row.id }})
			},
			seepayLog: function (index, row) {
				this.$router.push({ name: '店铺付费日志', query: { id: row.id }})
			},			
			// 前往权限界面
			permiss: function (id) {
				localStorage.setItem('dataListFrome', JSON.stringify(this.filters))
				localStorage.setItem('dataListPage', this.page)
				this.$router.push({ name: '店铺权限设置', query: { id: id }})
			},
            toNoticeSend:function(o){
				this.$router.push({ name: '通知发送', query: {store_id: o.id }})
            }
		},
		watch:{
			'time': function(newValue,oldVale){
				if(this.time){
					this.form.start_time = this.time[0]
					this.form.end_time = this.time[1]
				}else{
					this.time = []
				}
			}
		},
		created(){
			if(localStorage.getItem('dataListFrome')) this.filters = JSON.parse(localStorage.getItem('dataListFrome'))
			if(localStorage.getItem('dataListPage')) this.page = parseInt(localStorage.getItem('dataListPage'))
			this.lotterTypeStr = JSON.stringify(this.lotterType)
			//获取列表数据
			this.getList();
		},
		mounted() {
		}
	}

</script>

<style lang="scss" scoped>
	@import "../../styles/permission.scss";
	.w100{ width: 180px; }
	::v-deep.cz .el-input__inner{ width: 40px; padding: 0 4px; height: 28px; margin-left: 5px; text-align: center; }
</style>